import lozad from 'lozad';

function initLozad() {
    window.observer = lozad('.lazy');
    observer.observe();
}

if (document.readyState !== 'loading') {
    initLozad();
} else {
    document.addEventListener('DOMContentLoaded', initLozad);
}
